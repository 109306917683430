export default {
  computed: {
    // override this computed in the component to return where the thresholds are held
    thresholdObject () {
      return {}
    },
    thresholdErrorMessage () {
      const days = [
        this.thresholdObject.threshold1Day,
        this.thresholdObject.threshold2Day,
        this.thresholdObject.threshold3Day,
        this.thresholdObject.threshold4Day,
        this.thresholdObject.threshold5Day,
        this.thresholdObject.threshold6Day,
        this.thresholdObject.threshold7Day
      ].filter(v => typeof v === 'number' || !!v).map(v => Number(v))

      if (days.every((v, index) => {
        if (index === 0) return true
        if (v > days[index - 1]) return true
        return false
      })) {
        return null
      } else {
        return 'キャンセルポリシーの適用日は降順・重複なしで設定下さい'
      }
    }
  }
}
