<template>
  <base-fees-points>
    <template v-slot:default>
      <div class="text-right mb-3">
        <v-btn @click="visible = 1" class="btn--primary" :disabled="!checkPerUser">{{ $t("buttons.createANewPlan") }}</v-btn>
      </div>
      <v-data-table
        :no-data-text="$t('rules.noData')"
        :locale="$i18n.locale"
        :headers="headers"
        :items="bookingPlanList"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-2 table-custom table-no-pagination"
        :items-per-page="itemsPerPage"
        :server-items-length="totalCount"
        :footer-props="{
          itemsPerPageText:
            numLength +
            $t('common.cases') +
            (itemStart + 1) +
            '~' +
            itemStop +
            $t('common.showing'),
            itemsPerPageOptions: [10, 20, 50, 100, 200, 500]
        }"
        @pagination="getNumOfPage"
      >
        <template v-slot:[`item.enabled`]="{ item }">
          {{ item.enabled ? "有効" : "無効" }}
        </template>
        <template v-slot:[`item.webPublicFromDate`]="{ item }">
          {{
            calculateDays(toDay, item.webPublicFromDate) > 0 &&
            calculateDays(item.webPublicToDate, toDay) > 0
              ? "掲載中"
              : "終了"
          }}
        </template>
        <template v-slot:[`item.roomType`]="{ item }">
          {{ item.roomType.name }}
        </template>
        <template v-slot:[`item.facility`]="{ item }">
          {{ item.roomType.facility ? item.roomType.facility.name : "" }}
        </template>
        <template v-slot:[`item.webPublicToDate`]="{ item }">
          {{
            calculateDays(item.webPublicToDate, toDay) > 0
              ? calculateDays(item.webPublicToDate, toDay)
              : 0
          }}日
        </template>
        <template v-slot:[`item.additionalChargeType`]="{ item }">
          {{ getAdditionalChargeType(item.additionalChargeType) }}
        </template>
        <template v-slot:[`item.discount`]="{ item }">
          {{
            item.day2Discount > 0 ||
            item.day3Discount > 0 ||
            item.day4Discount > 0 ||
            item.day5Discount > 0 ||
            item.day6Discount > 0 ||
            item.day7Discount > 0 ||
            item.day8Discount > 0 ||
            item.day9Discount > 0
              ? "あり"
              : "なし"
          }}
        </template>
        <template v-slot:[`item.overrideCancelPolicy`]="{ item }">
          {{ item.overrideCancelPolicy ? "あり" : "なし" }}
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <v-btn small icon @click="editBookingPlan(item)" :disabled="!checkPerUser">
            <v-icon large color="var(--bg__light-blue)"
              >mdi-pencil-circle</v-icon
            >
          </v-btn>
        </template>
      </v-data-table>
      <CreatePlanDialog
        v-if="visible === 1"
        :visible="visible === 1"
        @close=" visible = -1; loadList();"
      ></CreatePlanDialog>
      <EditPlanDialog
        v-if="visible === 2"
        :visible="visible === 2"
        :item="currentItem"
        @close="visible = -1"
        @reloadData="loadList"
      ></EditPlanDialog>
    </template>
  </base-fees-points>
</template>

<script>
import BaseFeesPoints from '@/components/FeesPoints/BaseFeesPoints'
import CreatePlanDialog from './createPlanDialog'
import EditPlanDialog from './editPlanDialog'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { calculateDays } from '@/utils/handleDate'
import { getAdditionalChargeType } from '@/utils/getAdditionalChargeType'
import { checkPermissionUserCurrent } from '@/utils/permissions'

export default {
  name: 'Plans',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      toDay: new Date().toISOString().substr(0, 10),
      currentItem: {},
      itemsPerPage: 10,
      totalCount: 0,
      ready: false,
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      sortBy: 'id',
      sortDesc: false,
      headers: [
        {
          text: '',
          value: 'edit',
          sortable: false
        },
        {
          text: this.$t('feesPoints.plans.planID'),
          value: 'id',
          align: 'center',
          sortable: true
        },
        {
          text: this.$t('feesPoints.plans.enabledOrDisabled'),
          value: 'enabled',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('feesPoints.plans.published'),
          value: 'webPublicFromDate',
          sortable: false
        },
        {
          text: this.$t('feesPoints.plans.planName'),
          value: 'title',
          align: 'left',
          sortable: false
        },
        {
          text: this.$t('common.contract'),
          value: 'targetBookingType',
          align: 'left',
          sortable: false
        },
        {
          text: this.$t('common.facility'),
          value: 'facility',
          align: 'left',
          sortable: false
        },
        {
          text: this.$t('common.roomType'),
          value: 'roomType',
          sortable: false
        },
        {
          text: this.$t('feesPoints.plans.remainingPublication'),
          value: 'webPublicToDate',
          sortable: false
        },
        {
          text: this.$t('feesPoints.plans.possiblePeriodStart'),
          value: 'availableFromDate',
          sortable: false
        },
        {
          text: this.$t('feesPoints.plans.endOfPossiblePeriod'),
          value: 'availableToDate',
          sortable: false
        },
        {
          text: this.$t('feesPoints.plans.priceSetting'),
          value: 'additionalChargeType',
          sortable: false
        },
        {
          text: this.$t('feesPoints.plans.consecutiveNightDiscount'),
          value: 'discount',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('feesPoints.plans.cancelSetting'),
          value: 'overrideCancelPolicy',
          align: 'center',
          sortable: false
        }
      ],
      // Edit plan dialog--------------------
      visible: -1
      // items: [{
      //   id: 1,
      //   enabled: false,
      //   webPublicFromDate: '2021-08-01',
      //   webPublicToDate: '2021-10-16',
      //   title: 'title ne',
      //   targetBookingType: 'VS',
      //   roomType: {
      //     name: 'room type',
      //     facility: {
      //       name: 'facility'
      //     }
      //   },
      //   availableFromDate: '2021-10-01',
      //   availableToDate: '2021-10-15',
      //   additionalChargeType: 'RoomDay',
      //   day1Discount: 1000,
      //   overrideCancelPolicy: false
      // }
      // ]
    }
  },
  async mounted () {
    this.ready = true
    await this.loadList()
    // this.fetchBookingPlanList()
  },
  computed: {
    ...mapGetters(['bookingPlanList'])
  },
  methods: {
    calculateDays,
    getAdditionalChargeType,
    ...mapActions(['fetchBookingPlanList', 'fetchFacilityList']),
    ...mapMutations(['setBookingPlansPagi']),
    async loadList () {
      if (!this.ready) return
      const { total } = await this.fetchBookingPlanList({
        take: this.itemsPerPage,
        skip: this.itemStart
      })
      this.totalCount = total
    },

    getNumOfPage (pagination) {
      this.numLength = pagination.itemsLength
      this.itemStart = pagination.pageStart
      this.itemStop = pagination.pageStop
      this.itemsPerPage = pagination.itemsPerPage
      this.loadList()
    },

    async editBookingPlan (item) {
      await this.fetchFacilityList()
      this.currentItem = { ...item, roomType: { ...item.roomType } }
      this.visible = 2
    }
  },
  components: {
    BaseFeesPoints,
    CreatePlanDialog,
    EditPlanDialog
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-data-table {
    thead th span {
      color: #757575 !important;
      font-size: 14px !important;
    }
    tbody tr td {
      font-size: 16px !important;
      color: #757575 !important;
    }
  }
  .v-data-footer {
    color: #000000 !important;
    font-size: 13px !important;
  }
  .v-select__selections {
    .v-select__selection--comma {
      color: #000000 !important;
      font-size: 13px !important;
    }
  }
  span {
    svg {
      color: #333333 !important
    }
  }
}
</style>
