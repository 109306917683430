<template>
  <v-text-field
    :value="percentValue"
    @input="emitInput"
    type="number"
    :rules="[v => (v >= 0 && v <= 100) || '無効']"
    :readonly="readonly"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: Number,
    readonly: Boolean
  },
  computed: {
    percentValue () {
      if (typeof this.value === 'number') {
        return Math.floor(this.value * 100)
      }
      return null
    }
  },
  methods: {
    emitInput (percentValue) {
      if (typeof percentValue === 'string') {
        percentValue = Number(percentValue)
      }
      if (typeof percentValue === 'number') {
        this.$emit('input', percentValue / 100)
      }
    }
  }
}
</script>
