import { render, staticRenderFns } from "./BaseFeesPoints.vue?vue&type=template&id=0be04ac3&"
import script from "./BaseFeesPoints.vue?vue&type=script&lang=js&"
export * from "./BaseFeesPoints.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTab,VTabs})
