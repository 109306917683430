<template>
  <div>
    <template v-for="file, index in files">
      <v-file-input
        :key="`fi-${index}`"
        :value="forFileInput(file)"
        @change="setFile(index, $event)"
        :disabled="disabled"
        :rules="rules"
        v-bind="$attrs"
      />
      <v-img v-if="showPreview && getFileUrl(file)" :src="getFileUrl(file)" :key="`img-${index}`"/>
    </template>
    <v-messages class="mb-1" :value="messages" />
  </div>
</template>

<script>
import VInput from 'vuetify/lib/components/VInput/VInput'

export default {
  name: 'MultipleFiles',
  extends: VInput,
  props: {
    value: Array,
    disabled: Boolean,
    maxCount: {
      type: Number,
      default: 100
    },
    rules: Array,
    showPreview: Boolean
  },
  computed: {
    files () {
      const data = this.value?.slice() ?? []
      if (data.length >= this.maxCount) return data
      return data.concat([null])
    }
  },
  methods: {
    setFile (index, file) {
      const result = this.value?.slice() ?? []
      result.splice(index, 1, file)
      this.$emit('input', result)
      this.$emit('change', result)
    },
    forFileInput (file) {
      if (typeof file === 'string') {
        // this is the ur
        return null
      }
      return file
    },
    getFileUrl (file) {
      if (typeof file === 'string') return file
      if (!file) return null
      return URL.createObjectURL(file)
    }
  }
}
</script>
