<template>
  <div>
    <div class="header-main">
      <h1 class="py-4 page-title-main">
        {{ $t('routeTitles.feesPoints.feesPoints') }}
      </h1>
    </div>
    <template>
      <div class="px-main">
        <v-tabs v-model="tab" class="t-tab-custom">
          <v-tab v-for="(item, i) in items" :key="i" :to="item.to">{{
            item.title
          }}</v-tab>
        </v-tabs>
      <slot name="default" />
      </div>
    </template>
  </div>
</template>

<script>
import { checkPermissionTab } from '@/utils/permissions'

export default {
  name: 'BaseFeesPoints',
  data () {
    return {
      tab: null,
      items: [],
      itemsTmp: [
        {
          title: this.$t('routeTitles.feesPoints.feesAndPoints'),
          to: '/fees-points-plans/fees-and-points',
          pathRoot: '/fees-points-plans/fees-and-points',
          order: 1
        },
        {
          title: this.$t('routeTitles.feesPoints.plans'),
          to: '/fees-points-plans/plans',
          pathRoot: '/fees-points-plans/plans',
          order: 2
        },
        {
          title: this.$t('routeTitles.feesPoints.CSVUpdate'),
          to: '/fees-points-plans/csv-update',
          pathRoot: '/fees-points-plans/csv-update',
          order: 3
        }
      ]
    }
  },
  components: {

  },
  mounted () {
    this.checkPerTab()
  },
  methods: {
    checkPermissionTab,
    checkPerTab () {
      let itemCheck = this.checkPermissionTab(this.itemsTmp)
      itemCheck = [...new Set(itemCheck)]
      itemCheck.sort((a, b) => a.order - b.order)
      this.items = itemCheck
    }
  }
}
</script>

<style lang="scss">

</style>
