<template>
  <div>
    <v-dialog v-model="visible" persistent>
      <div class="--header d-flex justify-space-between bg--white">
        <h2 class="page-title-main">{{ $t("feesPoints.plans.planName") }}</h2>
        <div class="d-flex">
          <v-btn large class="btn--red-dark" @click="closeDialog(true)">
            <v-icon class="mr-2">mdi-close</v-icon>
            {{ $t("buttons.close") }}
          </v-btn>
          <v-btn large class="btn--primary ml-3" @click="save">
            {{ $t("buttons.save") }}
          </v-btn>
        </div>
      </div>

      <v-form ref="form" lazy-validation>
        <div class="line-item mb-0">
          <span class="text-brown"> プラン有効/無効 </span>
          <span>
            <v-select
              :items="enablePlanOptions"
              v-model="isEnableText"
              :rules="rules.isRequired"
              dense
              small
              color="var(--text__gray)"
              class="w-50"
            >
            </v-select>
          </span>
        </div>
        <h3 class="text--title text-center my-5">
          {{ $t("contractDetail.basicInformation") }}
        </h3>

        <v-divider></v-divider>

        <div class="line-item mt-8">
          <span class="text-brown">
            {{ $t("feesPoints.plans.planName") }}
          </span>
          <span>
            <v-text-field
              v-model="title"
              counter
              maxlength="50"
              :rules="rules.text50Required"
              class="pa-0"
            />
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">
            {{ $t("feesPoints.plans.planSubtitle") }}
          </span>
          <span>
            <v-text-field
              v-model="subtitle"
              counter
              maxlength="50"
              :rules="rules.text50Required"
              class="pa-0"
            >
            </v-text-field>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{
            $t("feesPoints.plans.contractToCreateAPlan")
          }}</span>
          <span>
            <v-select
              :items="bookingTypeList"
              v-model="targetBookingType"
              :rules="rules.isRequired"
              dense
              small
              color="var(--text__gray)"
              class="w-50"
            ></v-select>
          </span>
        </div>

        <div class="line-item mb-8">
          <span class="text-brown">{{
            $t("feesPoints.plans.facilitiesAndRoomTypes")
          }}</span>
          <span class="d-flex">
            <div class="mr-2">
              <label class="text--xsmall text-black">{{
                $t("common.facility")
              }}</label>
              <v-select
                :items="facilityList"
                v-model="facilitySelected"
                item-text="name"
                item-value="id"
                :rules="rules.isRequired"
                @change="getRoomTypeList(facilitySelected)"
                dense
                small
                color="var(--text__gray)"
                class="custom-select"
              ></v-select>
            </div>
            <div class="ml-2">
              <label class="text--xsmall text-black">{{
                $t("common.roomType")
              }}</label>
              <v-select
                :items="roomTypeList"
                v-model="roomTypeSelected"
                :rules="rules.isRequired"
                item-text="name"
                item-value="id"
                dense
                small
                color="var(--text__gray)"
                class="custom-select"
                :no-data-text="$t('rules.noData')"
              ></v-select>
            </div>
          </span>
        </div>

        <div class="line-item mb-0">
          <span class="mt-n7 text-brown">{{
            $t("feesPoints.plans.planImage")
          }}</span>
          <span>
            <multiple-files
              v-model="imageFiles"
              :messages="[$messages.fileLimit({ size: '10MB', type: '画像 (JPG、GIF、PNG, BMP)', multiple: 2 })]"
              @change="importImg"
              :rules="rules.importImgs"
              :placeholder="$t('feesPoints.plans.uploadPlanImages')"
              :maxCount="2"
              accept="image/png, image/jpeg, image/bmp, image/gif"
              prepend-icon="mdi-camera"
              showPreview
            />
          </span>
        </div>
        <div class="line-item mb-4">
          <span class="mt-n7 text-brown">{{
            $t("feesPoints.plans.planPrStatement")
          }}</span>
          <span>
            <v-textarea
              v-model="description"
              counter
              maxlength="2000"
              :rules="rules.text2000"
              outlined
              rows="4"
            >
            </v-textarea>
          </span>
        </div>

        <div class="line-item mb-4">
          <span class="mt-n7 text-brown">{{
            $t("feesPoints.plans.planNotes")
          }}</span>
          <span>
            <v-textarea
              v-model="remarks"
              counter
              maxlength="500"
              :rules="rules.text500"
              outlined
              rows="4"
            >
            </v-textarea>
          </span>
        </div>

        <!-- START Questions -->
        <div class="line-item">
          <span class="align-self-start text-brown">{{
            $t("common.question")
          }}</span>
          <span>
            <div
              v-for="(item, index) in questions"
              :key="index"
              class="pa-3 elevation-3 rounded-lg mb-4"
            >
              <label class="text--xsmall text-black">
                {{ $t("common.questionContent") + (index + 1) }}
              </label>
              <v-text-field
                v-model="item.text"
                counter
                maxlength="100"
                :rules="rules.text100"
                class="pa-0"
              ></v-text-field>
              <v-radio-group v-model="item.type" row>
                <v-radio :label="$t('common.freeText')" value="Text"></v-radio>
                <v-radio :label="$t('common.option')" value="Select"></v-radio>
              </v-radio-group>

              <div v-for="(qa, idx) in item.questionSelects" :key="idx">
                <label
                  class="text--xsmall text-black"
                  :class="item.type === 'Text' ? 'disable-text' : ''"
                >
                  {{ $t("common.questionOrder") + (idx + 1) }}
                </label>
                <v-text-field
                  v-model="qa.title"
                  counter
                  maxlength="50"
                  :rules="rules.text50"
                  class="pa-0"
                  :disabled="item.type === 'Text'"
                  :class="item.type === 'Text' ? 'disable-text' : ''"
                ></v-text-field>
              </div>

              <div class="d-flex justify-space-between align-center">
                <v-radio-group v-model="item.required" row hide-details>
                  <v-radio
                    :label="$t('common.requiredAnswer')"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :label="$t('common.optionalAnswer')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <v-btn @click="deleteAQuestion(index)" class="btn--red-dark">{{
                  $t("buttons.deleteQuestion")
                }}</v-btn>
              </div>
            </div>
            <v-btn
              @click="addAQuestion"
              :disabled="questions.length > 4"
              color="var(--text__primary)"
              outlined
            >
              {{ $t("buttons.addAQuestion") }}
            </v-btn>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{ $t("common.author") }}</span>
          <span>
            <v-text-field
              v-model="author"
              :rules="rules.text50Required"
              class="pa-0"
            >
            </v-text-field>
          </span>
        </div>
        <h3 class="text--title text-center my-5">
          {{ $t("feesPoints.plans.planFeeOrConditionSetting") }}
        </h3>

        <v-divider></v-divider>

        <div class="line-item mt-5">
          <span class="text-brown">{{
            $t("feesPoints.plans.priceSettingMethod")
          }}</span>
          <span>
            <v-radio-group
              v-model="chargeType"
              row
              :rules="rules.isRequired"
              class="mt-0"
              hide-details
              @change="changeChargeType"
            >
              <v-radio label="1室1滞在" value="RoomStay"></v-radio>
              <v-radio label="1名1滞在" value="PersonStay"></v-radio>
              <v-radio label="1室1泊" value="RoomDay"></v-radio>
              <v-radio label="1名1泊" value="PersonDay"></v-radio>
            </v-radio-group>
          </span>
        </div>

        <template v-if="chargeType">
          <div class="line-item" v-show="!isLockChangeAmount">
            <span class="text-brown">
              {{ $t("feesPoints.plans.priceField") }}
            </span>
            <span>
              <tvos-int-input jpy
                v-model="chargeAmount"
                type="number"
                :rules="[$rules.required]"
                :disabled="isLockChangeAmount"
                class="pa-0"
              />
            </span>
          </div>

          <div class="line-item" v-show="isLockChangeAmount">
            <span class="text-brown">{{
              $t("feesPoints.plans.pricePlanCSV")
            }}</span>
            <span>
              <v-file-input
                filled
                :rules="rules.importCSV"
                :disabled="!isLockChangeAmount"
                @change="importCsv"
                v-model="csvImported"
                ref="importCSV"
                accept=".csv"
                prepend-icon="mdi-file-document-outline"
                :label="$t('feesPoints.plans.uploadPlanPriceCSV')"
              ></v-file-input>
            </span>
          </div>
        </template>

        <div class="line-item">
          <span class="mb-n5 text-brown">{{
            $t("feesPoints.plans.settlementOfAccounts")
          }}</span>
          <div class="d-flex align-center">
            <div class="mr-3">
              <label class="text--xsmall text-black">{{
                $t("common.checkInDate")
              }}</label>
              <v-select
                :items="saleDayList"
                v-model="stopSaleDay"
                :rules="rules.isRequired"
                dense
                small
                color="var(--text__gray)"
                class="sort-item"
              ></v-select>
            </div>
            <label class="mb-n5 text-brown">
              {{ $t("feesPoints.plans.dayBefore") }}
            </label>
            <div class="mr-3 ml-6">
              <label class="text--xsmall text-black">{{
                $t("common.time")
              }}</label>
              <v-select
                :items="saleTimeList"
                :rules="rules.isRequired"
                v-model="stopSaleTime"
                dense
                small
                color="var(--text__gray)"
                class="sort-item"
              ></v-select>
            </div>
            <label class="mb-n5 text-brown">
              {{ $t("feesPoints.plans.reservationTimeNote") }}
            </label>
          </div>
        </div>

        <div class="line-item">
          <span class="mt-n10 text-brown">{{
            $t("feesPoints.plans.periodOfStay")
          }}</span>
          <span>
            <!-- ======= START Available-Date ======= -->
            <div class="d-flex">
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuAvailableFromDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.start") }}
                        </label>
                        <v-btn
                          :class="
                            !availableFromDate && isSubmit
                              ? 'null-date-picker'
                              : ''
                          "
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(availableFromDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    :rules="rules.isRequired"
                    v-model="availableFromDate"
                    :max="nextOrPrevDay(availableToDate, -1)"
                    :first-day-of-week="0"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuAvailableFromDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <label class="text-h5 align-self-end mx-1">〜</label>
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuAvailableToDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.end") }}
                        </label>
                        <v-btn
                          :class="
                            !availableToDate && isSubmit
                              ? 'null-date-picker'
                              : ''
                          "
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(availableToDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    :rules="rules.isRequired"
                    v-model="availableToDate"
                    :first-day-of-week="0"
                    :min="nextOrPrevDay(availableFromDate, 1)"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuAvailableToDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <!-- ======= END Available-Date ======= -->

            <div class="d-flex align-center mt-3 text-brown">
              {{ $t("feesPoints.plans.fromTheDateOfBooking") }}
              <tvos-int-input
                type="number"
                v-model="bookThroughDays"
                class="pa-0 sort-item mx-2 center-input"
              />
              {{ $t("feesPoints.plans.displayedUpUntilYesterday") }}
            </div>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{
            $t("feesPoints.plans.memberPagePostingPeriod")
          }}</span>
          <span>
            <!-- ======= START Web-Public-Date ======= -->
            <div class="d-flex">
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuWebPublicFromDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.start") }}
                        </label>
                        <v-btn
                          :class="
                            !webPublicFromDate && isSubmit
                              ? 'null-date-picker'
                              : ''
                          "
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(webPublicFromDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    :rules="rules.isRequired"
                    v-model="webPublicFromDate"
                    :first-day-of-week="0"
                    :max="nextOrPrevDay(webPublicToDate, -1)"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuWebPublicFromDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <label class="text-h5 align-self-end mx-1">〜</label>
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuWebPublicToDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.end") }}
                        </label>
                        <v-btn
                          :class="
                            !webPublicToDate && isSubmit
                              ? 'null-date-picker'
                              : ''
                          "
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(webPublicToDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    :rules="rules.isRequired"
                    v-model="webPublicToDate"
                    :first-day-of-week="0"
                    :min="nextOrPrevDay(webPublicFromDate, 1)"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuWebPublicToDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <!-- ======= END Web-Public-Date ======= -->
          </span>
        </div>

        <div class="line-item text-brown">
          <span>{{ $t("invitationsAndCoupons.reservationPeriod") }}</span>
          <span>
            <!-- ======= START Booking-Date  ======= -->
            <div class="d-flex">
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuBookingFromDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.start") }}
                        </label>
                        <v-btn
                          :class="
                            !bookingFromDate && isSubmit
                              ? 'null-date-picker'
                              : ''
                          "
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(bookingFromDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    :rules="rules.isRequired"
                    v-model="bookingFromDate"
                    :first-day-of-week="0"
                    :max="nextOrPrevDay(bookingToDate, -1)"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuBookingFromDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <label class="text-h5 align-self-end mx-1">〜</label>
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuBookingToDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.end") }}
                        </label>
                        <v-btn
                          :class="
                            !bookingToDate && isSubmit ? 'null-date-picker' : ''
                          "
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(bookingToDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    :rules="rules.isRequired"
                    v-model="bookingToDate"
                    :first-day-of-week="0"
                    :min="nextOrPrevDay(bookingFromDate, 1)"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuBookingToDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <!-- ======= END Booking-Date ======= -->
          </span>
        </div>
        <div class="line-item">
          <span class="text-brown">{{
            $t("feesPoints.plans.sellOffSetting")
          }}</span>
          <span class="d-flex">
            <div class="mr-4">
              <label class="text--xsmall text-black">{{
                $t("feesPoints.plans.maxNumOfUsersPerDay")
              }}</label>
              <tvos-int-input
                v-model="guestCountLimit"
                class="pa-0 sort-item mx-2 center-input"
                :max="10000"
                required
              />
            </div>
            <div>
              <label class="text--xsmall text-black">{{
                $t("feesPoints.plans.maxNumOfRoomsPerDay")
              }}</label>
              <tvos-int-input
                v-model="roomCountLimit"
                class="pa-0 sort-item mx-2 center-input"
                :max="10000"
                required
              />
            </div>
          </span>
        </div>

        <div class="line-item">
          <span class="align-self-start text-brown">{{
            $t("feesPoints.plans.roomRateDiscount")
          }}</span>
          <span>
            <v-radio-group
              v-model="discountSetting"
              @change="changeDiscountSetting"
              row
              hide-details
              class="pa-0 ma-0"
            >
              <v-radio :label="$t('common.noSet')" :value="false"></v-radio>
              <v-radio :label="$t('common.set')" :value="true"></v-radio>
            </v-radio-group>
            <div class="rounded-lg elevation-3 pa-5 mt-5">
              <v-radio-group
                v-model="discountType"
                row
                hide-details
                class="pa-0 ma-0 mb-1"
              >
                <v-radio
                  :label="$t('common.yen') + '/Pt' + $t('common.discount')"
                  value="Fixed"
                ></v-radio>
                <v-radio
                  :label="'%' + $t('common.discount')"
                  value="Percent"
                ></v-radio>
              </v-radio-group>
              <div class="sort-item">
                <label class="text--xsmall text-black"
                  >1{{ $t("common.night") }}</label
                >
                <tvos-int-input
                  v-model="day1Discount"
                  :rules="rules.discount"
                  :readonly="!discountSetting"
                  type="number"
                  class="pa-0"
                />
              </div>
              <div class="d-flex justify-space-between">
                <div class="sort-item">
                  <label class="text--xsmall text-black"
                    >2{{ $t("common.night") }}</label
                  >
                  <tvos-int-input
                    v-model="day2Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black"
                    >3{{ $t("common.night") }}</label
                  >
                  <tvos-int-input
                    v-model="day3Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black"
                    >4{{ $t("common.night") }}</label
                  >
                  <tvos-int-input
                    v-model="day4Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black"
                    >5{{ $t("common.night") }}</label
                  >
                  <tvos-int-input
                    v-model="day5Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div class="sort-item">
                  <label class="text--xsmall text-black"
                    >6{{ $t("common.night") }}</label
                  >
                  <tvos-int-input
                    v-model="day6Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black"
                    >7{{ $t("common.night") }}</label
                  >
                  <tvos-int-input
                    v-model="day7Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black"
                    >8{{ $t("common.night") }}</label
                  >
                  <tvos-int-input
                    v-model="day8Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black"
                    >9{{ $t("common.night") }}</label
                  >
                  <tvos-int-input
                    v-model="day9Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
              </div>
              <v-checkbox
                v-model="continueDiscountAfterDay9"
                hide-details
                :label="$t('feesPoints.plans.roomRateDiscountNote')"
              ></v-checkbox>
            </div>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{
            $t("feesPoints.plans.limitedNumOfPeople")
          }}</span>
          <span class="d-flex">
            <div class="mr-4">
              <label class="text--xsmall text-black">{{
                $t("feesPoints.plans.minNumOfUsers")
              }}</label>
              <v-select
                :items="minGuestCountList"
                :rules="rules.isRequired"
                v-model="minGuestCount"
                dense
                small
                color="var(--text__gray)"
                hide-details
                class="sort-item"
              ></v-select>
            </div>
            <div>
              <label class="text--xsmall text-black">{{
                $t("feesPoints.plans.maxNumOfUsers")
              }}</label>
              <v-select
                :items="maxGuestCountList"
                :rules="rules.isRequired"
                v-model="maxGuestCount"
                dense
                small
                color="var(--text__gray)"
                class="sort-item"
              ></v-select>
            </div>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{
            $t("feesPoints.plans.limitedNumOfNights")
          }}</span>
          <span class="d-flex">
            <div class="mr-4">
              <label class="text--xsmall text-black">{{
                $t("common.overNight")
              }}</label>
              <v-select
                :items="minDayList"
                v-model="minDays"
                dense
                small
                color="var(--text__gray)"
                :rules="rules.isRequired"
                class="sort-item"
              ></v-select>
            </div>
            <div>
              <label class="text--xsmall text-black">{{
                $t("common.untilNight")
              }}</label>
              <v-select
                :items="maxDayList"
                v-model="maxDays"
                dense
                small
                color="var(--text__gray)"
                :rules="rules.isRequired"
                class="sort-item"
              ></v-select>
            </div>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">利用可能な曜日</span>
          <v-select
            v-model="daysSelected"
            :items="daysOfWeek"
            :menu-props="{ maxHeight: '400' }"
            label="曜日を選択"
            multiple
            class="width-custom"
          ></v-select>
        </div>

        <div class="line-item threshold">
          <span class="align-self-start text-brown">{{
            $t("feesPoints.plans.roomRateCancellationPolicy")
          }}</span>
          <span>
            <v-radio-group
              :rules="rules.isRequiredBoolean"
              v-model="overrideCancelPolicy"
              @change="changeCancelPolicy"
              row
              hide-details
              class="pa-0 ma-0"
            >
              <v-radio :label="$t('common.noSet')" :value="true"></v-radio>
              <v-radio :label="$t('common.set')" :value="false"></v-radio>
            </v-radio-group>
            <div class="rounded-lg elevation-3 py-5 pl-10 pr-15 mt-5">
              <div class="d-flex justify-space-between">
                <div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="threshold7Day"
                      type="number"
                      :min="1"
                      :readonly="overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-black">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="threshold7Penalty"
                      :readonly="overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="threshold6Day"
                      type="number"
                      :min="1"
                      :readonly="overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-black">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="threshold6Penalty"
                      :readonly="overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="threshold5Day"
                      type="number"
                      :min="1"
                      :readonly="overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-black">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="threshold5Penalty"
                      :readonly="overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="threshold4Day"
                      type="number"
                      :min="1"
                      :readonly="overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-black">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="threshold4Penalty"
                      :readonly="overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                </div>
                <div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="threshold3Day"
                      type="number"
                      :min="1"
                      :readonly="overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-black">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="threshold3Penalty"
                      :readonly="overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="threshold2Day"
                      type="number"
                      :min="1"
                      :readonly="overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-black">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="threshold2Penalty"
                      :readonly="overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="threshold1Day"
                      type="number"
                      :min="1"
                      :readonly="overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-black">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="threshold1Penalty"
                      :readonly="overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item --between text-black">
                    <span class="mb-7">{{ $t("reports.onTheDay") }}</span>
                    <span>
                      <percent-input
                        v-model="day0Penalty"
                        :readonly="overrideCancelPolicy"
                      ></percent-input>
                      %
                    </span>
                  </div>

                  <div class="cancel-item --between">
                    <span class="mb-7 text-black">{{
                      $t("feesPoints.plans.noContact")
                    }}</span>
                    <span>
                      <percent-input
                        v-model="noShowPenalty"
                        :readonly="overrideCancelPolicy"
                      ></percent-input>
                      %
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="thresholdErrorMessage" class="error--text">
                {{ thresholdErrorMessage }}
              </div>
            </div>
          </span>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { formatDate, getCopyErrorTextView, handlErrorView } from '@/constants/functions'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import { ROOM_TYPE_LIST } from '@/api/graphql/facility/facility-room-type'
import { CREATE_BOOKING_PLAN } from '@/api/graphql/feesPointsPlans/booking-plans'
import { nextOrPrevDay } from '@/utils/handleDate'
import { MAX_INT } from '@/constants/enum'
import { GENERATE_UPLOAD_URL } from '@/api/graphql/csv/uploadCsvAccommodationPrice'
import { mapActions, mapMutations } from 'vuex'
import axios from 'axios'
import gql from 'graphql-tag'
import percentInput from './percentInput.vue'
import planDialogMixin from './planDialogMixin'
import MultipleFiles from '@/components/Input/MultipleFiles.vue'

export default {
  components: {
    percentInput,
    MultipleFiles
  },
  name: 'CreatePlanDialog',
  mixins: [planDialogMixin],
  props: {
    visible: Boolean
  },
  data () {
    return {
      isSubmit: false,
      csvImported: null,
      isLockChangeAmount: false,
      imageFiles: [],
      imgUpload: [],
      imagePublicUrl1: null,
      imagePublicUrl2: null,
      facilityList: [],
      facilitySelected: null,
      enablePlanOptions: ['有効', '無効'],
      isEnableText: null,
      isEnable: true,
      title: null,
      subtitle: null,
      bookingTypeList: ['VS', 'MO', 'SP', 'FP'],
      targetBookingType: null,
      roomTypeList: [],
      roomTypeSelected: null,
      description: null,
      remarks: null,
      questions: [
        {
          text: null,
          type: 'Text', // Text / Select
          required: true,
          questionSelects: [
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            }
          ]
        }
      ],
      author: null, // createdByName
      chargeType: null,
      chargeAmount: null, // fixedAdditionalCharge && fixedAdditionalChargeChild
      saleDayList: [],
      stopSaleDay: null,
      menuTime: false,
      stopSaleTime: null,
      menuAvailableFromDate: false,
      menuAvailableToDate: false,
      availableFromDate: null,
      availableToDate: null,
      menuWebPublicFromDate: false,
      menuWebPublicToDate: false,
      bookThroughDays: null,
      webPublicFromDate: '',
      webPublicToDate: '',
      menuBookingFromDate: false,
      menuBookingToDate: false,
      bookingFromDate: '',
      bookingToDate: '',
      guestPerDay: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      roomsPerDay: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      guestCountLimit: null,
      roomCountLimit: null,
      discountSetting: null,
      discountType: '',
      day1Discount: null,
      day2Discount: null,
      day3Discount: null,
      day4Discount: null,
      day5Discount: null,
      day6Discount: null,
      day7Discount: null,
      day8Discount: null,
      day9Discount: null,
      continueDiscountAfterDay9: false,
      minGuestCountList: [1, 2, 3, 4, 5],
      maxGuestCountList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      minGuestCount: null,
      maxGuestCount: null,
      minDayList: [1, 2, 3, 4, 5],
      maxDayList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      minDays: null,
      maxDays: null,
      overrideCancelPolicy: null,
      threshold1Day: null,
      threshold1Penalty: null,
      threshold2Day: null,
      threshold2Penalty: null,
      threshold3Day: null,
      threshold3Penalty: null,
      threshold4Day: null,
      threshold4Penalty: null,
      threshold5Day: null,
      threshold5Penalty: null,
      threshold6Day: null,
      threshold6Penalty: null,
      threshold7Day: null,
      threshold7Penalty: null,
      day0Penalty: null,
      noShowPenalty: null,
      csvFileS3Key: '',
      daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
      daysSelected: [],
      availableWeekDays: {
        availableSunday: false,
        availableMonday: false,
        availableTuesday: false,
        availableWednesday: false,
        availableThursday: false,
        availableFriday: false,
        availableSaturday: false
      },

      rules: {
        isRequired: [(val) => !!val || this.$t('rules.isRequired')],
        isRequiredBoolean: [
          (val) => typeof val === 'boolean' || this.$t('rules.isRequired')
        ],
        text50Required: [
          (val) => (!!val && val.length < 51) || this.$t('rules.isRequired')
        ],
        text50: [
          (val) => val?.length < 51 || !val || this.$t('rules.isInvalid')
        ],
        text100: [
          (val) => val?.length < 101 || !val || this.$t('rules.isInvalid')
        ],
        text2000: [
          (val) => val?.length < 2001 || !val || this.$t('rules.isInvalid')
        ],
        text500: [
          (val) => val?.length < 501 || !val || this.$t('rules.isInvalid')
        ],
        importImgs: [
          (value) =>
            this.checkSize(value) ||
            !value ||
            '最大サイズを超えています' // || value.reduce((size, file) => size + file.size, 0) < 2000000 ||
        ],
        importCSV: [
          (value) =>
            !value || value.size < 10485760 || '100MBを超えることはできません'
        ],
        discount: [
          (v) =>
            (v >= 0 &&
              v < MAX_INT &&
              this.discountType === 'Fixed') ||
            (v >= 0 &&
              v < 101 &&
              this.discountType === 'Percent') ||
            this.$t('rules.isInvalid')
        ]
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    saleTimeList () {
      var arr = []
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          arr.push('0' + i + ':00' + ':00')
        } else {
          arr.push(i + ':00' + ':00')
        }
        // arr.push(i)
      }
      return arr
    },
    thresholdObject () {
      return this
    }
  },
  mounted () {
    this.getFacilityNameList()
    this.setSaleDayList()
  },
  methods: {
    ...mapMutations([
      'setErrorDialog',
      'setAlertSuccess',
      'setAlertError',
      'setLoadingOverlayHide',
      'setLoadingOverlayShow',
      'setPermissionUser',
      'setRoleAdminUser',
      'setCopyErrorText'
    ]),
    ...mapActions(['fetchBookingPlanList']),
    nextOrPrevDay,
    formatDate,
    // ------------ START Upload files --------------------------
    checkSize (v) {
      if (v) {
        if (v.size > 10485760) {
          return false
        }
      }
      return true
    },
    funCheckMax (val) {
      if (val && val <= 10000 && val >= 1) {
        return true
      }
      return '1から10000の数字でご入力下さい'
    },
    async importImg (v) {
      this.imgUpload = []
      if (v?.length > 0) {
        for (let i = 0; i < v.length; i++) {
          if (v[i] && v[i].size < 10485760) {
            await this.$apollo
              .mutate({
                mutation: GENERATE_UPLOAD_URL,
                variables: {
                  type: 'bookingPlan/image',
                  contentType: 'image/jpeg',
                  contentLength: v[i].size
                }
              })
              .then((data) => {
                this.imgUpload.push({
                  url: data.data.generateUploadUrl.uploadUrl,
                  fileUrl: data.data.generateUploadUrl.fileUrl,
                  data: v[i]
                })
              })
              .catch((error) => {
                console.error(error)
              })
          }
        }
      }
    },
    async putImg () {
      if (this.imgUpload?.length > 0) {
        for (let i = 0; i < this.imgUpload.length; i++) {
          await axios
            .put(this.imgUpload[i].url, this.imgUpload[i].data, {
              headers: {
                'Content-Type': this.imgUpload[i].data.type
              }
            })
            .then((data) => {
              console.log(data)
            })
            .catch((error) => {
              console.error(error)
            })
        }
      }
    },
    async importCsv (v) {
      if (v && v.size < 10485760) {
        await this.$apollo
          .mutate({
            mutation: GENERATE_UPLOAD_URL,
            variables: {
              type: 'bookingPlan/bookingPlanCharge',
              contentType: 'text/csv',
              contentLength: v.size
            }
          })
          .then((data) => {
            this.csvFileS3Key = data.data.generateUploadUrl.key
            const uploadUrl = data.data.generateUploadUrl.uploadUrl
            axios.put(uploadUrl, v, {
              headers: {
                'Content-Type': 'text/csv'
              }
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    // ------------ END Upload files --------------------------
    changeChargeType (v) {
      if (v === 'RoomDay' || v === 'PersonDay') {
        this.chargeAmount = 0
        this.isLockChangeAmount = true
      } else {
        this.isLockChangeAmount = false
        this.csvImported = null
        this.csvFileS3Key = null
      }
    },
    setSaleDayList () {
      for (let i = 0; i < 100; i++) {
        this.saleDayList.push(i + 1)
      }
    },
    setAvailableWeekDays (v) {
      switch (v) {
        case '日':
          this.availableWeekDays.availableSunday = true
          break
        case '月':
          this.availableWeekDays.availableMonday = true
          break
        case '火':
          this.availableWeekDays.availableTuesday = true
          break
        case '水':
          this.availableWeekDays.availableWednesday = true
          break
        case '木':
          this.availableWeekDays.availableThursday = true
          break
        case '金':
          this.availableWeekDays.availableFriday = true
          break
        case '土':
          this.availableWeekDays.availableSaturday = true
          break
      }
    },
    async save () {
      this.isSubmit = true
      this.imagePublicUrl1 = this.imgUpload[0]?.fileUrl || null
      this.imagePublicUrl2 = this.imgUpload[1]?.fileUrl || null
      this.isEnable = this.isEnableText === '有効'
      if (
        this.$refs.form.validate() &&
        !this.thresholdErrorMessage &&
        this.availableFromDate &&
        this.availableToDate &&
        this.webPublicFromDate &&
        this.webPublicToDate &&
        this.bookingFromDate &&
        this.bookingToDate
      ) {
        await this.putImg()
        this.questions.forEach((item) => {
          if (item.type === 'Select') {
            item.questionSelects = item.questionSelects.filter(
              (el) => el.title
            )
          } else {
            item.questionSelects = []
          }
        })
        for (let i = 0; i < this.daysSelected.length; i++) {
          this.setAvailableWeekDays(this.daysSelected[i])
        }
        await this.createBookingPlan()
        this.$emit('close')
        this.questions = []
        this.addAQuestion()
      }
    },
    closeDialog (check) {
      this.isSubmit = false
      if (check) {
        this.$refs.form.resetValidation()
        this.questions.length = 1
      }
      this.$emit('close')
    },
    addAQuestion () {
      this.questions.length < 5 &&
        this.questions.push({
          text: null,
          type: 'Text', // Text / Select
          required: true,
          questionSelects: [
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            }
          ]
        })
    },
    deleteAQuestion (idx) {
      if (this.questions.length > 1) {
        this.questions.splice(idx, 1)
      }
    },
    changeDiscountSetting () {
      if (!this.discountType) {
        // set this as it is required
        this.discountType = 'Fixed'
      }
      this.day1Discount = '0'
      this.day2Discount = '0'
      this.day3Discount = '0'
      this.day4Discount = '0'
      this.day5Discount = '0'
      this.day6Discount = '0'
      this.day7Discount = '0'
      this.day8Discount = '0'
      this.day9Discount = '0'
    },
    changeCancelPolicy () {
      this.threshold1Day = null
      this.threshold1Penalty = null
      this.threshold2Day = null
      this.threshold2Penalty = null
      this.threshold3Day = null
      this.threshold3Penalty = null
      this.threshold4Day = null
      this.threshold4Penalty = null
      this.threshold5Day = null
      this.threshold5Penalty = null
      this.threshold6Day = null
      this.threshold6Penalty = null
      this.threshold7Day = null
      this.threshold7Penalty = null
      this.day0Penalty = null
      this.noShowPenalty = null
    },
    async getFacilityNameList () {
      await this.$apollo
        .query({
          query: gql`
            ${FACILITY_LIST}
          `
        })
        .then((data) => {
          this.facilityList = data.data.facilityList
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async getRoomTypeList (id) {
      await this.$apollo
        .query({
          query: gql`
            ${ROOM_TYPE_LIST}
          `,
          variables: {
            facilityId: parseInt(id)
          }
        })
        .then((data) => {
          this.roomTypeList = data.data.roomTypeList
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async createBookingPlan () {
      this.setLoadingOverlayShow()
      const variables = {
        csvFileS3Key: this.csvFileS3Key,
        enabled: this.isEnable,
        title: this.title,
        subtitle: this.subtitle,
        targetBookingType: this.targetBookingType,
        roomTypeId: parseInt(this.roomTypeSelected),
        imagePublicUrl1: this.imagePublicUrl1,
        imagePublicUrl2: this.imagePublicUrl2,
        remarks: this.remarks,
        description: this.description,
        questions: this.questions,
        createdByName: this.author,
        chargeType: this.chargeType,
        chargeAmount: parseInt(this.chargeAmount),
        stopSaleDay: parseInt(this.stopSaleDay),
        stopSaleTime: this.stopSaleTime,
        availableFromDate: this.availableFromDate,
        availableToDate: this.availableToDate,
        bookThroughDays: parseInt(this.bookThroughDays),
        webPublicFromDate: this.webPublicFromDate,
        webPublicToDate: this.webPublicToDate,
        bookingFromDate: this.bookingFromDate,
        bookingToDate: this.bookingToDate,
        guestCountLimit: parseInt(this.guestCountLimit),
        roomCountLimit: parseInt(this.roomCountLimit),
        discountType: this.discountType,
        day1Discount: parseInt(this.day1Discount),
        day2Discount: parseInt(this.day2Discount),
        day3Discount: parseInt(this.day3Discount),
        day4Discount: parseInt(this.day4Discount),
        day5Discount: parseInt(this.day5Discount),
        day6Discount: parseInt(this.day6Discount),
        day7Discount: parseInt(this.day7Discount),
        day8Discount: parseInt(this.day8Discount),
        day9Discount: parseInt(this.day9Discount),
        continueDiscountAfterDay9: this.continueDiscountAfterDay9,
        minGuestCount: parseInt(this.minGuestCount),
        maxGuestCount: parseInt(this.maxGuestCount),
        minDays: parseInt(this.minDays),
        maxDays: parseInt(this.maxDays),
        overrideCancelPolicy: this.overrideCancelPolicy,
        noShowPenalty: parseFloat(this.noShowPenalty),
        day0Penalty: parseFloat(this.day0Penalty),
        threshold1Day: parseInt(this.threshold1Day),
        threshold1Penalty: parseFloat(this.threshold1Penalty),
        threshold2Day: parseInt(this.threshold2Day),
        threshold2Penalty: parseFloat(this.threshold2Penalty),
        threshold3Day: parseInt(this.threshold3Day),
        threshold3Penalty: parseFloat(this.threshold3Penalty),
        threshold4Day: parseInt(this.threshold4Day),
        threshold4Penalty: parseFloat(this.threshold4Penalty),
        threshold5Day: parseInt(this.threshold5Day),
        threshold5Penalty: parseFloat(this.threshold5Penalty),
        threshold6Day: parseInt(this.threshold6Day),
        threshold6Penalty: parseFloat(this.threshold6Penalty),
        threshold7Day: parseInt(this.threshold7Day),
        threshold7Penalty: parseFloat(this.threshold7Penalty),
        availableWeekDays: this.availableWeekDays
      }
      await this.$apollo
        .mutate({
          mutation: gql`
            ${CREATE_BOOKING_PLAN}
          `,
          variables
        })
        .then((data) => {
          this.fetchBookingPlanList()
          this.setLoadingOverlayHide()
          if (data) {
            this.setAlertSuccess(this.$t('messages.successfulNewCreation'))
          }
        })
        .catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(getCopyErrorTextView(CREATE_BOOKING_PLAN, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
          console.log(error)
          throw error
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .null-date-picker {
    border: solid 1px red !important;
  }
}
::v-deep {
  .v-label {
    color: #444444 !important;
  }
  .text-black {
    color: #000000 !important;
  }
  .text-brown {
    color: #444444 !important;
  }
}
::v-deep {
  .width-custom {
    max-width: 216px !important;
  }
  .disable-text {
    color: var(--text__inactive) !important;
    .v-text-field__details {
      * {
        color: var(--text__inactive) !important;
      }
    }
  }
  .custom-select {
    max-width: 196px !important;
  }
  .threshold {
    .v-messages__message {
      line-height: 1.2 !important;
      font-size: 10px !important;
    }
  }
  .v-messages__message {
    line-height: 1.2 !important;
  }
  .v-dialog {
    position: relative;
    width: 800px;
    background-color: var(--bg__white);
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
    .--header {
      position: sticky;
      padding: 30px 30px 10px 30px;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .v-form {
      padding: 0 30px 30px 30px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  &.--between {
    justify-content: space-between;
    span {
      &:first-child {
        padding: 0 !important;
        align-self: flex-end;
        text-align: left;
        white-space: nowrap;
        text-align: left !important;
      }
      &:last-child {
        display: flex;
        align-items: baseline;
        width: auto !important;
      }
    }
  }
}
.line-item {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 35px;
  }
  span {
    display: inline-block;
    &:first-child {
      width: 30%;
      padding-right: 20px;
      text-align: right;
    }
    &:last-child {
      width: 70%;
    }
  }
}
label.text--xsmall {
  font-weight: 600;
}
.w-auto {
  width: auto !important;
}
</style>
