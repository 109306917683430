<template>
  <div>
    <v-dialog v-model="visible" persistent>
      <div class="--header d-flex justify-space-between bg--white">
        <h2 class="page-title-main">{{ $t("feesPoints.plans.planName") }}</h2>
        <div class="d-flex">
          <v-btn large class="btn--red-dark" @click="closeDialog(true)">
            <v-icon class="mr-2">mdi-close</v-icon>
            {{ $t("buttons.close") }}
          </v-btn>
          <v-btn large class="btn--primary ml-3" @click="save">
            {{ $t("buttons.save") }}
          </v-btn>
        </div>
      </div>

      <v-form ref="form" lazy-validation>
        <div class="line-item">
          <span class="text-brown">
            {{ $t("feesPoints.plans.planID") }}
          </span>
          <span class="text-brown"> {{ item.id }} </span>
        </div>

        <div class="line-item mb-0 text-brown">
          <span>
            プラン有効/無効
          </span>
          <span>
            <v-select
              :items="enablePlanOptions"
              v-model="item.enabled"
              item-text="text"
              item-value="value"
              :rules="rules.isRequired"
              dense
              small
              color="var(--text__gray)"
              class="w-50"
            >
            </v-select>
          </span>
        </div>
        <h3 class="text--title text-center my-5">
          {{ $t("contractDetail.basicInformation") }}
        </h3>

        <v-divider></v-divider>

        <div class="line-item mt-8">
          <span class="text-brown">
            {{ $t("feesPoints.plans.planName") }}
          </span>
          <span>
            <v-text-field
              v-model="item.title"
              counter
              maxlength="50"
              :rules="rules.text50Required"
              class="pa-0"
            />
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">
            {{ $t("feesPoints.plans.planSubtitle") }}
          </span>
          <span>
            <v-text-field
              v-model="item.subtitle"
              counter
              maxlength="50"
              :rules="rules.text50Required"
              class="pa-0"
            >
            </v-text-field>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{ $t("feesPoints.plans.contractToCreateAPlan") }}</span>
          <span>
            <v-select
              :items="bookingTypeList"
              v-model="item.targetBookingType"
              :rules="rules.isRequired"
              dense
              small
              color="var(--text__gray)"
              class="w-50"
            ></v-select>
          </span>
        </div>

        <div class="line-item mb-8">
          <span class="text-brown">{{ $t("feesPoints.plans.facilitiesAndRoomTypes") }}</span>
          <span class="d-flex">
            <div class="mr-2">
              <label class="text--xsmall text-black">{{ $t("common.facility") }}</label>
              <v-select
                :items="facilityList"
                v-model="item.roomType.facilityId"
                item-text="name"
                item-value="id"
                :rules="rules.isRequired"
                @change="getRoomTypeList($event, true)"
                dense
                small
                color="var(--text__gray)"
                class="custom-select"
              ></v-select>
            </div>
            <div class="ml-2">
              <label class="text--xsmall text-black">{{ $t("common.roomType") }}</label>
              <v-select
                :items="roomTypeList"
                v-model="item.roomType.id"
                :rules="rules.isRequired"
                item-text="name"
                item-value="id"
                dense
                small
                color="var(--text__gray)"
                class="custom-select"
                :no-data-text="$t('rules.noData')"
              ></v-select>
            </div>
          </span>
        </div>

        <div class="line-item mb-0">
          <span class="mt-n7 text-brown">{{ $t("feesPoints.plans.planImage") }}</span>
          <span>
            <multiple-files
              v-model="imageFiles"
              :messages="[$messages.fileLimit({ size: '10MB', type: '画像 (JPG、GIF、PNG, BMP)', multiple: 2 })]"
              :rules="rules.importImgs"
              :placeholder="$t('feesPoints.plans.uploadPlanImages')"
              :maxCount="2"
              accept="image/png, image/jpeg, image/bmp, image/gif"
              prepend-icon="mdi-camera"
              showPreview
            />
          </span>
        </div>
        <div class="line-item mb-4">
          <span class="mt-n7 text-brown">{{
            $t("feesPoints.plans.planPrStatement")
          }}</span>
          <span>
            <v-textarea
              v-model="item.description"
              counter
              maxlength="2000"
              :rules="rules.text2000"
              outlined
              rows="4"
            >
            </v-textarea>
          </span>
        </div>

        <div class="line-item mb-4">
          <span class="mt-n7 text-brown">{{ $t("feesPoints.plans.planNotes") }}</span>
          <span>
            <v-textarea
              v-model="item.remarks"
              counter
              maxlength="500"
              :rules="rules.text500"
              outlined
              rows="4"
            >
            </v-textarea>
          </span>
        </div>

        <!-- START Questions -->
        <div class="line-item">
          <span class="align-self-start text-brown">{{ $t("common.question") }}</span>
          <span>
            <div
              v-for="(item, index) in questions"
              :key="index"
              class="pa-3 elevation-3 rounded-lg mb-4"
            >
              <label class="text--xsmall text-black">
                {{ $t("common.questionContent") + (index + 1) }}
              </label>
              <v-text-field
                v-model="item.text"
                counter
                maxlength="100"
                :rules="rules.text100"
                class="pa-0"
              ></v-text-field>
              <v-radio-group v-model="item.type" row>
                <v-radio :label="$t('common.freeText')" value="Text"></v-radio>
                <v-radio :label="$t('common.option')" value="Select"></v-radio>
              </v-radio-group>

              <div v-for="(qa, idx) in item.questionSelects" :key="idx">
                <label
                  class="text--xsmall text-black"
                  :class="item.type === 'Text' ? 'disable-text' : ''"
                >
                  {{ $t("common.questionOrder") + (idx + 1) }}
                </label>
                <v-text-field
                  v-model="qa.title"
                  counter
                  maxlength="50"
                  :rules="rules.text50"
                  class="pa-0"
                  :disabled="item.type === 'Text'"
                  :class="item.type === 'Text' ? 'disable-text' : ''"
                ></v-text-field>
              </div>

              <div class="d-flex justify-space-between align-center">
                <v-radio-group v-model="item.required" row hide-details>
                  <v-radio
                    :label="$t('common.requiredAnswer')"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :label="$t('common.optionalAnswer')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
                <v-btn @click="deleteAQuestion(index)" :disabled="questions.length === 1" class="btn--red-dark">{{
                  $t("buttons.deleteQuestion")
                }}</v-btn>
              </div>
            </div>
            <v-btn
              @click="addAQuestion"
              v-if="questions.length < 4"
              color="var(--text__primary)"
              outlined
            >
              {{ $t("buttons.addAQuestion") }}
            </v-btn>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{ $t("common.author") }}</span>
          <span>
            <v-text-field
              v-model="item.createdByName"
              :rules="rules.text50Required"
              class="pa-0"
            >
            </v-text-field>
          </span>
        </div>
        <h3 class="text--title text-center my-5">
          {{ $t("feesPoints.plans.planFeeOrConditionSetting") }}
        </h3>

        <v-divider></v-divider>

        <div class="line-item mt-5">
          <span class="text-brown">{{ $t("feesPoints.plans.priceSettingMethod") }}</span>
          <span>
            <v-radio-group
              v-model="item.additionalChargeType"
              row
              :rules="rules.isRequired"
              class="mt-0"
              hide-details
              @change="changeChargeType"
            >
              <v-radio label="1室1滞在" value="RoomStay"></v-radio>
              <v-radio label="1名1滞在" value="PersonStay"></v-radio>
              <v-radio label="1室1泊" value="RoomDay"></v-radio>
              <v-radio label="1名1泊" value="PersonDay"></v-radio>
            </v-radio-group>
          </span>
        </div>

        <template v-if="item.additionalChargeType">
          <div class="line-item" v-show="!isLockChangeAmount">
            <span class="text-brown">
              {{ $t("feesPoints.plans.priceField") }}
            </span>
            <span>
              <tvos-int-input jpy
                v-model="item.fixedAdditionalCharge"
                type="number"
                :rules="[$rules.required]"
                :disabled="isLockChangeAmount"
                class="pa-0"
              />
            </span>
          </div>

          <div class="line-item" v-show="isLockChangeAmount">
            <span class="mt-n7">{{ $t("feesPoints.plans.pricePlanCSV") }}</span>
            <span>
              <v-file-input
                class="text-truncate"
                filled
                :rules="rules.importCSV"
                :disabled="!isLockChangeAmount"
                @change="importCsv"
                v-model="csvImported"
                ref="importCSV"
                accept=".csv"
                prepend-icon="mdi-file-document-outline"
                :label="$t('feesPoints.plans.uploadPlanPriceCSV')"
              ></v-file-input>
            </span>
          </div>
        </template>

        <div class="line-item">
          <span class="mb-n5 text-brown">{{
            $t("feesPoints.plans.settlementOfAccounts")
          }}</span>
          <div class="d-flex align-center">
            <div class="mr-3">
              <label class="text--xsmall text-black">{{ $t("common.checkInDate") }}</label>
              <v-select
                :items="saleDayList"
                v-model="item.stopSaleDay"
                :rules="rules.isRequired"
                dense
                small
                color="var(--text__gray)"
                class="sort-item"
              ></v-select>
            </div>
            <label class="mb-n5 text-brown">
              {{ $t("feesPoints.plans.dayBefore") }}
            </label>
            <div class="mr-3 ml-6">
              <label class="text--xsmall text-black">{{ $t("common.time") }}</label>
              <v-select
                :items="saleTimeList"
                v-model="item.stopSaleTime"
                :rules="rules.isRequired"
                dense
                small
                color="var(--text__gray)"
                class="sort-item"
              ></v-select>
            </div>
            <label class="mb-n5 text-brown">
              {{ $t("feesPoints.plans.reservationTimeNote") }}
            </label>
          </div>
        </div>

        <div class="line-item">
          <span class="mt-n10 text-brown">{{ $t("feesPoints.plans.periodOfStay") }}</span>
          <span>
            <!-- ======= START Available-Date ======= -->
            <div class="d-flex">
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuAvailableFromDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.start") }}
                        </label>
                        <v-btn
                          v-model="item.availableFromDate"
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(item.availableFromDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="item.availableFromDate"
                    :max="nextOrPrevDay(item.availableToDate, -1)"
                    :first-day-of-week="0"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuAvailableFromDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <label class="text-h5 align-self-end mx-1">〜</label>
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuAvailableToDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.end") }}
                        </label>
                        <v-btn
                          v-model="item.availableToDate"
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(item.availableToDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="item.availableToDate"
                    :first-day-of-week="0"
                    :min="nextOrPrevDay(item.availableFromDate, 1)"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuAvailableToDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <!-- ======= END Available-Date ======= -->

            <div class="d-flex align-center mt-3 text-brown">
              {{ $t("feesPoints.plans.fromTheDateOfBooking") }}
              <tvos-int-input
                type="number"
                v-model="item.bookThroughDays"
                class="pa-0 sort-item mx-2 center-input"
              />
              {{ $t("feesPoints.plans.displayedUpUntilYesterday") }}
            </div>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{ $t("feesPoints.plans.memberPagePostingPeriod") }}</span>
          <span>
            <!-- ======= START Web-Public-Date ======= -->
            <div class="d-flex">
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuWebPublicFromDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.start") }}
                        </label>
                        <v-btn
                          v-model="item.webPublicFromDate"
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(item.webPublicFromDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="item.webPublicFromDate"
                    :first-day-of-week="0"
                    :max="nextOrPrevDay(item.webPublicToDate, -1)"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuWebPublicFromDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <label class="text-h5 align-self-end mx-1">〜</label>
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuWebPublicToDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.end") }}
                        </label>
                        <v-btn
                          v-model="item.webPublicToDate"
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(item.webPublicToDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="item.webPublicToDate"
                    :first-day-of-week="0"
                    :min="nextOrPrevDay(item.webPublicFromDate, 1)"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuWebPublicToDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <!-- ======= END Web-Public-Date ======= -->
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{ $t("invitationsAndCoupons.reservationPeriod") }}</span>
          <span>
            <!-- ======= START Booking-Date  ======= -->
            <div class="d-flex">
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuBookingFromDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.start") }}
                        </label>
                        <v-btn
                          v-model="item.bookingFromDate"
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(item.bookingFromDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="item.bookingFromDate"
                    :first-day-of-week="0"
                    :max="nextOrPrevDay(item.bookingToDate, -1)"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuBookingFromDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <label class="text-h5 align-self-end mx-1">〜</label>
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuBookingToDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon class="align-self-end mr-1"
                        >mdi-calendar-month</v-icon
                      >
                      <div>
                        <label class="text--xsmall d-block mb-1 text-black">
                          {{ $t("common.end") }}
                        </label>
                        <v-btn
                          v-model="item.bookingToDate"
                          v-on="on"
                          small
                          class="btn--date"
                          >{{ formatDate(item.bookingToDate) }}
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="item.bookingToDate"
                    :first-day-of-week="0"
                    :min="nextOrPrevDay(item.bookingFromDate, 1)"
                    class="v-date-picker-custom"
                    :locale="$i18n.locale"
                    scrollable
                    @input="menuBookingToDate = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <!-- ======= END Booking-Date ======= -->
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{ $t("feesPoints.plans.sellOffSetting") }}</span>
          <span class="d-flex">
            <div class="mr-4">
              <label class="text--xsmall text-black">{{
                $t("feesPoints.plans.maxNumOfUsersPerDay")
              }}</label>
              <tvos-int-input
                v-model="item.guestCountLimit"
                class="pa-0 sort-item mx-2 center-input"
                :max="10000"
                required
              />
            </div>
            <div>
              <label class="text--xsmall text-black">{{
                $t("feesPoints.plans.maxNumOfRoomsPerDay")
              }}</label>
              <tvos-int-input
                v-model="item.roomCountLimit"
                class="pa-0 sort-item mx-2 center-input"
                :max="10000"
                required
              />
            </div>
          </span>
        </div>

        <div class="line-item">
          <span class="align-self-start text-brown">{{
            $t("feesPoints.plans.roomRateDiscount")
          }}</span>
          <span>
            <v-radio-group
              v-model="discountSetting"
              @change="changeDiscountSetting"
              row
              hide-details
              class="pa-0 ma-0"
            >
              <v-radio :label="$t('common.noSet')" :value="false"></v-radio>
              <v-radio :label="$t('common.set')" :value="true"></v-radio>
            </v-radio-group>
            <div class="rounded-lg elevation-3 pa-5 mt-5">
              <v-radio-group
                v-model="item.discountType"
                row
                hide-details
                class="pa-0 ma-0 mb-1"
              >
                <v-radio
                  :label="$t('common.yen') + '/Pt' + $t('common.discount')"
                  value="Fixed"
                ></v-radio>
                <v-radio
                  :label="'%' + $t('common.discount')"
                  value="Percent"
                ></v-radio>
              </v-radio-group>
              <div class="sort-item">
                <label class="text--xsmall text-black">1{{ $t("common.night") }}</label>
                <tvos-int-input
                  v-model="item.day1Discount"
                  :rules="rules.discount"
                  :readonly="!discountSetting"
                  type="number"
                  class="pa-0"
                />
              </div>
              <div class="d-flex justify-space-between">
                <div class="sort-item">
                  <label class="text--xsmall text-black">2{{ $t("common.night") }}</label>
                  <tvos-int-input
                    v-model="item.day2Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black">3{{ $t("common.night") }}</label>
                  <tvos-int-input
                    v-model="item.day3Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black">4{{ $t("common.night") }}</label>
                  <tvos-int-input
                    v-model="item.day4Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black">5{{ $t("common.night") }}</label>
                  <tvos-int-input
                    v-model="item.day5Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div class="sort-item">
                  <label class="text--xsmall text-black">6{{ $t("common.night") }}</label>
                  <tvos-int-input
                    v-model="item.day6Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black">7{{ $t("common.night") }}</label>
                  <tvos-int-input
                    v-model="item.day7Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black">8{{ $t("common.night") }}</label>
                  <tvos-int-input
                    v-model="item.day8Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
                <div class="sort-item">
                  <label class="text--xsmall text-black">9{{ $t("common.night") }}</label>
                  <tvos-int-input
                    v-model="item.day9Discount"
                    :rules="rules.discount"
                    :readonly="!discountSetting"
                    type="number"
                    class="pa-0"
                  />
                </div>
              </div>
              <v-checkbox
                v-model="item.continueDiscountAfterDay9"
                hide-details
                :label="$t('feesPoints.plans.roomRateDiscountNote')"
              ></v-checkbox>
            </div>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{ $t("feesPoints.plans.limitedNumOfPeople") }}</span>
          <span class="d-flex">
            <div class="mr-4">
              <label class="text--xsmall text-black">{{
                $t("feesPoints.plans.minNumOfUsers")
              }}</label>
              <v-select
                :items="minGuestCountList"
                v-model="item.minGuestCount"
                dense
                small
                color="var(--text__gray)"
                hide-details
                class="sort-item"
              ></v-select>
            </div>
            <div>
              <label class="text--xsmall text-black">{{
                $t("feesPoints.plans.maxNumOfUsers")
              }}</label>
              <v-select
                :items="maxGuestCountList"
                v-model="item.maxGuestCount"
                dense
                small
                color="var(--text__gray)"
                class="sort-item"
              ></v-select>
            </div>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">{{ $t("feesPoints.plans.limitedNumOfNights") }}</span>
          <span class="d-flex">
            <div class="mr-4">
              <label class="text--xsmall text-black">{{ $t("common.overNight") }}</label>
              <v-select
                :items="minDayList"
                v-model="item.minDays"
                dense
                small
                color="var(--text__gray)"
                :rules="rules.isRequired"
                class="sort-item"
              ></v-select>
            </div>
            <div>
              <label class="text--xsmall text-black">{{ $t("common.untilNight") }}</label>
              <v-select
                :items="maxDayList"
                v-model="item.maxDays"
                dense
                small
                color="var(--text__gray)"
                :rules="rules.isRequired"
                class="sort-item"
              ></v-select>
            </div>
          </span>
        </div>

        <div class="line-item">
          <span class="text-brown">利用可能な曜日</span>
          <v-select
            v-model="daysSelected"
            :items="daysOfWeek"
            :menu-props="{ maxHeight: '400' }"
            label="曜日を選択"
            multiple
            class="width-custom"
          ></v-select>
        </div>

        <div class="line-item threshold">
          <span class="align-self-start text-brown">{{
            $t("feesPoints.plans.roomRateCancellationPolicy")
          }}</span>
          <span>
            <v-radio-group
              v-model="item.overrideCancelPolicy"
              @change="changeCancelPolicy"
              row
              hide-details
              class="pa-0 ma-0"
            >
              <v-radio :label="$t('common.noSet')" :value="false"></v-radio>
              <v-radio :label="$t('common.set')" :value="true"></v-radio>
            </v-radio-group>
            <div class="rounded-lg elevation-3 py-5 pl-10 pr-15 mt-5">
              <div class="d-flex justify-space-between">
                <div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="item.threshold7Day"
                      type="number"
                      :min="1"
                      :readonly="!item.overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-brown">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="item.threshold7Penalty"
                      :readonly="!item.overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="item.threshold6Day"
                      type="number"
                      :min="1"
                      :readonly="!item.overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-brown">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="item.threshold6Penalty"
                      :readonly="!item.overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="item.threshold5Day"
                      type="number"
                      :min="1"
                      :readonly="!item.overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-brown">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="item.threshold5Penalty"
                      :readonly="!item.overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="item.threshold4Day"
                      type="number"
                      :min="1"
                      :readonly="!item.overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-brown">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="item.threshold4Penalty"
                      :readonly="!item.overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                </div>
                <div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="item.threshold3Day"
                      type="number"
                      :min="1"
                      :readonly="!item.overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-brown">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="item.threshold3Penalty"
                      :readonly="!item.overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="item.threshold2Day"
                      type="number"
                      :min="1"
                      :readonly="!item.overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-brown">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="item.threshold2Penalty"
                      :readonly="!item.overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item">
                    <tvos-int-input
                      v-model="item.threshold1Day"
                      type="number"
                      :min="1"
                      :readonly="!item.overrideCancelPolicy"
                    />
                    <span class="mx-2 mb-5 text-brown">{{
                      $t("feesPoints.plans.dayBefore")
                    }}</span>
                    <percent-input
                      v-model="item.threshold1Penalty"
                      :readonly="!item.overrideCancelPolicy"
                    ></percent-input>
                    %
                  </div>
                  <div class="cancel-item --between">
                    <span class="mb-7 text-brown">{{ $t("reports.onTheDay") }}</span>
                    <span>
                      <percent-input
                        v-model="item.day0Penalty"
                        :readonly="!item.overrideCancelPolicy"
                      ></percent-input>
                      %
                    </span>
                  </div>

                  <div class="cancel-item --between">
                    <span class="mb-7 text-brown">{{
                      $t("feesPoints.plans.noContact")
                    }}</span>
                    <span>
                      <percent-input
                        v-model="item.noShowPenalty"
                        :readonly="!item.overrideCancelPolicy"
                      ></percent-input>
                      %
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="thresholdErrorMessage" class="error--text">{{thresholdErrorMessage}}</div>
            </div>
            <div class="text-right mt-6">
              <v-btn large class="btn--red-dark" @click="deleteAction">
                {{ $t("buttons.deletePlan") }}
              </v-btn>
            </div>
          </span>
        </div>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/constants/functions'
import { ROOM_TYPE_LIST } from '@/api/graphql/facility/facility-room-type'
import { nextOrPrevDay } from '@/utils/handleDate'
import { MAX_INT } from '@/constants/enum'
import { GENERATE_UPLOAD_URL } from '@/api/graphql/csv/uploadCsvAccommodationPrice'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import gql from 'graphql-tag'
import percentInput from './percentInput.vue'
import planDialogMixin from './planDialogMixin'
import MultipleFiles from '@/components/Input/MultipleFiles.vue'

export default {
  components: {
    percentInput,
    MultipleFiles
  },
  name: 'EditPlanDialog',
  mixins: [planDialogMixin],
  props: {
    visible: Boolean,
    item: Object
  },
  data () {
    return {
      saleTimeList: [],
      csvImported: null,
      imageFiles: [],
      imgUpload: [],
      enablePlanOptions: [
        {
          text: '有効',
          value: true
        },
        {
          text: '無効',
          value: false
        }
      ],
      bookingTypeList: ['VS', 'MO', 'SP', 'FP'],
      roomTypeList: [],
      questions: [
        {
          text: null,
          type: 'Text', // Text / Select
          required: true,
          questionSelects: [
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            }
          ]
        }
      ],
      saleDayList: [],
      menuAvailableFromDate: false,
      menuAvailableToDate: false,
      menuWebPublicFromDate: false,
      menuWebPublicToDate: false,
      menuBookingFromDate: false,
      menuBookingToDate: false,
      menuTime: false,
      guestPerDay: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      roomsPerDay: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      discountSetting: true,
      minGuestCountList: [1, 2, 3, 4, 5],
      maxGuestCountList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      minDayList: [1, 2, 3, 4, 5],
      maxDayList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      csvFileS3Key: '',
      daysOfWeek: ['日', '月', '火', '水', '木', '金', '土'],
      daysSelected: [],
      availableWeekDays: {
        availableSunday: false,
        availableMonday: false,
        availableTuesday: false,
        availableWednesday: false,
        availableThursday: false,
        availableFriday: false,
        availableSaturday: false
      },

      rules: {
        isRequired: [val => val !== null || this.$t('rules.isRequired')],
        text50Required: [
          val => (!!val && val.length < 51) || this.$t('rules.isRequired')
        ],
        text50: [val => val?.length < 51 || !val || this.$t('rules.isInvalid')],
        text100: [
          val => val?.length < 101 || !val || this.$t('rules.isInvalid')
        ],
        text2000: [
          val => val?.length < 2001 || !val || this.$t('rules.isInvalid')
        ],
        text500: [
          val => val?.length < 501 || !val || this.$t('rules.isInvalid')
        ],
        importImgs: [
          value =>
            this.checkSize(value) ||
            !value ||
            '最大サイズを超えています' // || value.reduce((size, file) => size + file.size, 0) < 2000000 ||
        ],
        importCSV: [
          value =>
            !value || value.size < 10485760 || '100MBを超えることはできません'
        ],
        discount: [
          v =>
            (v >= 0 &&
              v < MAX_INT &&
              this.item.discountType === 'Fixed') ||
            (v >= 0 &&
              v < 101 &&
              this.item.discountType === 'Percent') ||
            this.$t('rules.isInvalid')
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['facilityList']),
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    thresholdObject () { return this.item },
    isLockChangeAmount () {
      if (this.item.additionalChargeType === 'RoomDay' || this.item.additionalChargeType === 'PersonDay') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    item: {
      immediate: true,
      handler () {
        this.imageFiles = [this.item.imagePublicUrl1, this.item.imagePublicUrl2]
        if ([
          this.item.day1Discount,
          this.item.day2Discount,
          this.item.day3Discount,
          this.item.day4Discount,
          this.item.day5Discount,
          this.item.day6Discount,
          this.item.day7Discount,
          this.item.day8Discount,
          this.item.day9Discount
        ].every(discount => {
          return parseInt(discount, 10) === 0
        })) {
          this.discountSetting = false
        }
      }
    }
  },
  async mounted () {
    await this.getQuestions()
    this.getRoomTypeList(this.item.roomType.facilityId)
    this.getAvailableWeekDays()
    this.setSaleDayList()
    this.saleTimeList = []
    for (let i = 0; i <= 23; i++) {
      if (i < 10) {
        this.saleTimeList.push('0' + i + ':00:00')
      } else this.saleTimeList.push(i + ':00:00')
    }
  },
  methods: {
    ...mapMutations(['setAlertSuccess', 'setLoadingOverlayShow', 'setLoadingOverlayHide']),
    ...mapActions(['deleteBookingPlan', 'updateBookingPlan']),
    nextOrPrevDay,
    formatDate,
    getAvailableWeekDays () {
      this.daysSelected = [
        ...this.item.availableSunday ? '日' : [],
        ...this.item.availableMonday ? '月' : [],
        ...this.item.availableTuesday ? '火' : [],
        ...this.item.availableWednesday ? '水' : [],
        ...this.item.availableThursday ? '木' : [],
        ...this.item.availableFriday ? '金' : [],
        ...this.item.availableSaturday ? '土' : []
      ]
    },
    funCheckMax (val) {
      if (val && val <= 10000 && val >= 1) {
        return true
      }
      return '1から10000数字でご入力下さい'
    },
    getQuestions () {
      const questions = []
      for (let i = 0; i < 5; i++) {
        if (this.item[`question${i + 1}Type`]) {
          questions.push(
            {
              text: this.item[`question${i + 1}Text`],
              type: this.item[`question${i + 1}Type`],
              required: this.item[`question${i + 1}Required`],
              questionSelects: [
                {
                  title: this.item[`question${i + 1}Select1`]
                },
                {
                  title: this.item[`question${i + 1}Select2`]
                },
                {
                  title: this.item[`question${i + 1}Select3`]
                },
                {
                  title: this.item[`question${i + 1}Select4`]
                },
                {
                  title: this.item[`question${i + 1}Select5`]
                }
              ]
            }
          )
        }
      }
      if (questions.length > 0) {
        this.questions = questions
      }
    },
    // ------------ START Upload files --------------------------
    checkSize (v) {
      if (v) {
        if (v.size > 10485760) {
          return false
        }
      }
      return true
    },
    async putImg () {
      for (let i = 0; i < this.imageFiles.length; i++) {
        const imageFile = this.imageFiles[i]
        if (typeof imageFile === 'string') {
          // this is already uploaded, skip
        } else if (!imageFile) {
          // if not set, skip
        } else {
          this.setLoadingOverlayShow()
          try {
            const result = await this.$apollo
              .mutate({
                mutation: GENERATE_UPLOAD_URL,
                variables: {
                  type: 'bookingPlan/image',
                  contentType: imageFile.type,
                  contentLength: imageFile.size
                }
              })
              .then(data => {
                return {
                  url: data.data.generateUploadUrl.uploadUrl,
                  fileUrl: data.data.generateUploadUrl.fileUrl,
                  data: imageFile
                }
              })

            await axios
              .put(
                result.url,
                result.data
                ,
                {
                  headers: {
                    'Content-Type': result.data.type
                  }
                }
              )
            this.imageFiles.splice(i, 1, result.fileUrl)
          } catch (e) {
            console.error(e)
          } finally {
            this.setLoadingOverlayHide()
          }
        }
      }
    },
    async importCsv (v) {
      if (v && v.size < 10485760) {
        await this.$apollo
          .mutate({
            mutation: GENERATE_UPLOAD_URL,
            variables: {
              type: 'bookingPlan/bookingPlanCharge',
              contentType: 'text/csv',
              contentLength: v.size
            }
          })
          .then(data => {
            this.csvFileS3Key = data.data.generateUploadUrl.key
            const uploadUrl = data.data.generateUploadUrl.uploadUrl
            axios.put(
              uploadUrl,
              v,
              {
                headers: {
                  'Content-Type': 'text/csv'
                }
              })
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    // ------------ END Upload files --------------------------
    changeChargeType (v) {
      if (v === 'RoomDay' || v === 'PersonDay') {
        this.chargeAmount = 0
      } else {
        this.csvImported = null
        this.csvFileS3Key = null
      }
    },
    setSaleDayList () {
      for (let i = 0; i < 100; i++) {
        this.saleDayList.push(i + 1)
      }
    },
    setSaleTimeList () {
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          this.saleTimeList.push('0' + i + ':00' + ':00')
        } else {
          this.saleTimeList.push(i + ':00' + ':00')
        }
      }
    },
    setAvailableWeekDays (v) {
      switch (v) {
        case '日':
          this.availableWeekDays.availableSunday = true
          break
        case '月':
          this.availableWeekDays.availableMonday = true
          break
        case '火':
          this.availableWeekDays.availableTuesday = true
          break
        case '水':
          this.availableWeekDays.availableWednesday = true
          break
        case '木':
          this.availableWeekDays.availableThursday = true
          break
        case '金':
          this.availableWeekDays.availableFriday = true
          break
        case '土':
          this.availableWeekDays.availableSaturday = true
          break
      }
    },
    async save () {
      if (this.$refs.form.validate() && !this.thresholdErrorMessage) {
        await this.handleData().then(async () => {
          this.item.imagePublicUrl1 = this.imageFiles[0]
          this.item.imagePublicUrl2 = this.imageFiles[1]
          await this.updateBookingPlan({
            item: this.item,
            csvFileS3Key: this.csvFileS3Key,
            availableWeekDays: this.availableWeekDays,
            questions: this.questions
          })
        }).then(() => this.$emit('reloadData'))
        this.$emit('close')
      }
    },
    async handleData () {
      await this.putImg().then(() => {
        this.questions.forEach(item => {
          if (item.type === 'Select') {
            item.questionSelects = item.questionSelects.filter(el => el.title)
          } else {
            item.questionSelects = []
          }
        })
      }
      ).then(() => {
        for (let i = 0; i < this.daysSelected.length; i++) {
          this.setAvailableWeekDays(this.daysSelected[i])
        }
      })
    },
    closeDialog (check) {
      if (check) {
        this.$refs.form.resetValidation()
        this.questions.length = 1
      }
      this.$emit('close')
    },
    addAQuestion () {
      this.questions.length < 5 &&
        this.questions.push({
          text: null,
          type: 'Text', // Text / Select
          required: true,
          questionSelects: [
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            },
            {
              title: null
            }
          ]
        })
    },
    deleteAQuestion (idx) {
      if (this.questions.length > 1) {
        this.questions.splice(idx, 1)
      }
    },
    changeDiscountSetting () {
      if (!this.item.discountType) {
        // set this as it is required
        this.item.discountType = 'Fixed'
      }
      this.item.day1Discount = 0
      this.item.day2Discount = 0
      this.item.day3Discount = 0
      this.item.day4Discount = 0
      this.item.day5Discount = 0
      this.item.day6Discount = 0
      this.item.day7Discount = 0
      this.item.day8Discount = 0
      this.item.day9Discount = 0
    },
    changeCancelPolicy () {
      this.item.threshold1Day = null
      this.item.threshold1Penalty = null
      this.item.threshold2Day = null
      this.item.threshold2Penalty = null
      this.item.threshold3Day = null
      this.item.threshold3Penalty = null
      this.item.threshold4Day = null
      this.item.threshold4Penalty = null
      this.item.threshold5Day = null
      this.item.threshold5Penalty = null
      this.item.threshold6Day = null
      this.item.threshold6Penalty = null
      this.item.threshold7Day = null
      this.item.threshold7Penalty = null
      this.item.day0Penalty = null
      this.item.noShowPenalty = null
    },
    async getRoomTypeList (id, isEdit) {
      if (isEdit) this.item.roomType.id = null
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${ROOM_TYPE_LIST}`,
          variables: {
            facilityId: parseInt(id)
          }
        })
        .then(data => {
          this.roomTypeList = data.data.roomTypeList
          this.setLoadingOverlayHide()
        })
        .catch(error => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },
    async deleteAction () {
      await this.deleteBookingPlan(this.item.id)
      this.$emit('reloadData')
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-label {
    color: #444444 !important
  }
  .text-black{
    color: #000000 !important
  }
  .text-brown {
    color: #444444 !important;
  }
}
::v-deep {
  .width-custom {
    max-width: 216px !important;
  }
  .disable-text {
    color: var(--text__inactive) !important;
    .v-text-field__details {
      * {
        color: var(--text__inactive) !important;
      }
    }
  }
  .custom-select {
    max-width: 196px !important;
  }
  .threshold {
    .v-messages__message {
      line-height: 1.2 !important;
      font-size: 10px !important;
    }
  }
  .v-messages__message {
    line-height: 1.2 !important;
  }
  .v-dialog {
    position: relative;
    width: 800px;
    background-color: var(--bg__white);
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
    .--header {
      position: sticky;
      padding: 30px 30px 10px 30px;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .v-form {
      padding: 0 30px 30px 30px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  &.--between {
    justify-content: space-between;
    span {
      &:first-child {
        padding: 0 !important;
        align-self: flex-end;
        text-align: left;
        white-space: nowrap;
        text-align: left !important;
      }
      &:last-child {
        display: flex;
        align-items: baseline;
        width: auto !important;
      }
    }
  }
}
.line-item {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 35px;
  }
  span {
    display: inline-block;
    &:first-child {
      width: 30%;
      padding-right: 20px;
      text-align: right;
    }
    &:last-child {
      width: 70%;
    }
  }
}
label.text--xsmall {
  font-weight: 600;
}
.w-auto {
  width: auto !important;
}
</style>
